import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [{
	path: '/',
	name: 'layout',
	component: () => import( /* webpackChunkName: "app2" */ '../views/layout/app.vue'),
}]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
