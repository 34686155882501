var authLogin = {
    apiUrl: '',
    def: '',
    config: {},
    onPost: false,
    dev: function() {
        var ua = navigator.userAgent.toLowerCase();
        var isWx = /micromessenger/i.test(ua);
        var isQQ = /qq/i.test(ua);
        var isWb = /weibo/i.test(ua);
        var isLine = /line/i.test(ua);
        var isTt = /twitter/i.test(ua);
        var isFb = function() { var ua = navigator.userAgent || navigator.vendor || window.opera; return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1); }();
        var dev = '';
        if (isWx) {
            dev = 'weixin';
        } else if (isQQ) {
            dev = 'qq';
        } else if (isWb) {
            dev = 'weibo';
        } else if (isFb) {
            dev = 'facebook';
        } else if (isLine) {
            dev = 'line';
        } else if (isTt) {
            dev = 'twitter';
        }
        return dev;
    },
    urlps: function(a) {
        var b, c, d, e, f, g, h, i, j, k = "";
        for (a.indexOf("#") >= 0 && (k = a.substr(a.indexOf("#") + 1, a.length), a = a.replace("#" + k, "")), b = "", a.indexOf("?") >= 0 && (b = a.substr(a.indexOf("?") + 1, a.length), a = a.replace("?" + b, "")), c = a, d = b.split("&"), e = d.length, h = [], i = [], f = 0; e > f; f++) g = d[f].split("="), h[g[0]] = g[1];
        for (j = k.split("&"), e = j.length, f = 0; e > f; f++) g = j[f].split("="), i[g[0]] = g[1];
        return {
            lists: function() { return h },
            data: function(a, b) {
                if ("undefined" == typeof b) { var c = h[a.toLowerCase()]; return "undefined" == typeof c ? "" : c }
                h[a] = b
            },
            hash: function(a, b) {
                if ("undefined" == typeof b) { var c = i[a.toLowerCase()]; return "undefined" == typeof c ? "" : c }
                i[a] = b
            },
            getHash: function() { var a, b, c = []; for (a in i) i[a] && c.push(a + "=" + i[a]); return b = "", c.length > 0 && (b = c.join("&")), b },
            url: function() {
                var a, b, d, e = [];
                for (a in h) h[a] && e.push(a + "=" + h[a]);
                b = "", e.length > 0 && (b = "?" + e.join("&")), e = [];
                for (a in i) i[a] && e.push(a + "=" + i[a]);
                return d = "", e.length > 0 && (d = "#" + e.join("&")), c + "" + b + d
            }
        }
    }
}

export default authLogin;