import Config from '../config'
import Axios from 'axios'

function buildApiBase(type, url, params, host) {
	let axioUrl
	// if(!host){
	// 	axioUrl = Config.apiUrl + url
	// }else{
		axioUrl = url
	// }
	console.log(axioUrl)
	Axios.defaults.withCredentials = true;
	let ret
	if ('get' == type) {
		ret = Axios({
			method: type,
			url: axioUrl,
			params,
			paramsSerializer: function(params) {
				return $.param(params, true)
			}

		})
	} else {
		ret = Axios[type](axioUrl, params)
	}
	return ret
}

export async function buildApi(type, url, params, host) {
	try {

		let ret = await buildApiBase(type, url, params, host)
		return ret.data;
	} catch (e) {}
}
