import Vue from 'vue'
import App from './App.vue'
import router from './router'
import $ from "jquery"
import "./css/index.scss";
Vue.config.productionTip = false
import Api from './api/index.js'
import authLogin from "./until/function/authLogin.js"
Vue.prototype.$Api = Api;
Vue.prototype.$AuthLogin = authLogin;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
